<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-7">
        Commercially, potatoes can be peeled by treatment with NaOH which helps loosen the skins
        which are then removed by spraying with water.
      </p>

      <p class="mb-3">
        a) If a
        <number-value :value="volNaOH" unit="\text{mL}" />
        solution of NaOH is titrated to the endpoint using
        <number-value :value="volHCl" unit="\text{mL}" />
        of
        <number-value :value="concHCl" unit="\text{M HCl}" />, what is the concentration of the NaOH
        solution?
      </p>

      <calculation-input
        v-model="inputs.concNaOH"
        class="mb-7"
        prepend-text="$\text{[NaOH]}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <p class="mb-1">
        b) To adequately loosen the potato skins, the sodium hydroxide must have a concentration in
        the range of 3.00 - 6.00 M. Would the solution described in part a) be appropriate for this
        application?
      </p>

      <v-radio-group v-model="inputs.appropriateness" :disabled="!allowEditing">
        <div v-for="option in options" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <chemical-latex :content="option.expression" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question239',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        concNaOH: null,
        appropriateness: null,
      },
      options: [
        {expression: 'Yes', value: 'yes'},
        {expression: 'No', value: 'no'},
      ],
    };
  },
  computed: {
    volNaOH() {
      return this.taskState.getValueBySymbol('volNaOH').content;
    },
    volHCl() {
      return this.taskState.getValueBySymbol('volHCl').content;
    },
    concHCl() {
      return this.taskState.getValueBySymbol('concHCl').content;
    },
  },
};
</script>
